import React, { useState } from "react";
import { NavLink } from "react-router-dom"

import "./Home.css";
import LargeCard from "../components/LargeCard";
import games from "../games";


export default function Home() {
    const [search, setSearch] = useState('');
    return (
        <div>
            <div id = 'header'> 
                <div id = 'black'>
                   <h1> Fun for everyone </h1>
                   <h3> Browse our most popular games. </h3>
                   <input id = 'search' 
                        placeholder = "search by name or tag" 
                        value = {search}
                        onChange = {e => setSearch(e.target.value)} />
                </div>
            </div>
            <hr />
            {games.filter(game => 
                    game.name.toLowerCase().includes(search.toLowerCase()) || game.tags.some(tag => tag.toLowerCase().includes(search.toLowerCase()))
                )
                .map((game, i) => (
                <LargeCard key = {i} message = {game.special}>
                    <div id = 'new-game-content' style = {{backgroundImage: `url(${game.img})`}}>
                        <div id = 'new-game-text'>
                            <h2 id = 'game-name'> {game.name} </h2>
                            <p> {game.description} </p>
                            <a href = {game.name} id = 'play'> 
                                Play 
                                <i className="ri-play-fill"></i>
                            </a>
                        </div>
                        <div id = 'tags'>
                            
                            {game.tags.map((tag, i) => (
                                <div key = {i} className = 'tag'> {tag} </div>
                            ))}
                        </div>
                    </div>
                </LargeCard>
            ))}
        </div>
    )
}