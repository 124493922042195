import React from 'react';

import useGameData from './useGameLogic.ts';

import "./MemoryCardGame.css";

export default (props) => {
    const game = useGameData(props);
    (props.gameStarted && !game.gameOn) && game.startGame()
    return (
        <>
            {/*!game.gameOn && <button onClick = {game.startGame}> Start </button>*/}
            {!game.gameOn && game.time ? (
                <div id = "result" style = {{zIndex: 2, position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", background: "#1c1c1c"}}>
                    <div>
                        <h1> Game Over! </h1>
                        
                        <h3> Time: {game.time} seconds </h3>
                    </div>
                </div>
            ): null}
            {/*<h1> {
                //game.timeToMemorize > 0 ? 'Time left to memorize: ' + game.timeToMemorize + ' seconds': 
            'Time: ' + game.time +  (game.time === 1 ? ' second' : ' seconds') } </h1>*/}
               
                <div id = 'grid-container' 
                    style = {{
                        display: 'inline-grid', 
                        gridGap: 10,
                        opacity: game.cardsFlipped.length !==  16 ? 1 : 0.4
                    }}>
                        {game.cards.map((card, i) => (
                            <button className = 'card' style = {{
                                    backgroundImage: !card.isFlipped && card.background.includes('url') && card.background,
                                    backgroundColor: !card.isFlipped && card.background,
                                    color: 'white',
                                    border: 'none',
                                    boxShadow: '5px 5px 5px grey',
                                    backgroundSize: 'cover',
                                    animationName: game.useAnimation && (game.currentCardsFlipped.some(card => card.index === i)  ? 'flip' : card.isFlipped && card.isWrong && 'unflip'),
                                    animationDuration: '1s'
                                }}
                                onClick = {() => game.flip(i)}
                                disabled = {!game.gameOn || (
                                            game.cardsFlipped.some(card => card.index === i) || 
                                            game.currentCardsFlipped.some(card => card.index === i) || 
                                            game.currentCardsFlipped.length >= 2)}
                                >
                                    
                                { !game.img && (game.cardsFlipped.some(card => card.index === i)  && 'Match!') }

                            </button>
                        ))}
                </div>
        </>
    )
}