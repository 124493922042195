import React, {useRef} from "react";

import { NavLink } from "react-router-dom";

import "./Navbar.css";

export default function Navbar() {
    const nav = useRef(null)
    
    return (
        <nav ref = {nav}>
            <div>
                <NavLink to = "/" id = "logo"> 210games.com </NavLink> 
            </div>
        </nav>
    )
}