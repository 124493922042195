export function shuffle(array: any[ ]): any[ ] {
    let shuffledArray: any[] = []
    const instancesOf = (value: any, arr: any[ ]) => arr.filter(val => val === value).length
    array.forEach(() => {
        let availableCharacters = 
            array.filter(value => (
                instancesOf(value, shuffledArray) < instancesOf(value, array)
            ))
        shuffledArray.push(availableCharacters[
            Math.floor(Math.random() * availableCharacters.length)
        ])
    })
    return shuffledArray
}