import { useState, useEffect } from 'react';

export default (runCondition: boolean)  => {
    const [ time, setTime ]: [ number,  any ] = useState(0);
    useEffect((): void => {
        runCondition && setTimeout((): void => {
            setTime((time: number) => time + 1);
        }, 1000);
    }, [ time, runCondition ]);

    return [ time, setTime ];
} 