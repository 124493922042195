import React from 'react'
import useGameData from './useGameData'

import "./Hangman.css";
export default props => {
    const game  = useGameData()
    return (
            <>        
                <span> Score: {game.state.score ? game.state.score : 0} </span>
                <br />
                <img alt = "hangman"src = {`../hangman-images/${game.state.lives}.jpg`} width = {500}/>
                <br />
                lives remaining: {game.state.lives}
                <div> {game.characters} {game.state.gameOn && <> <br /> {game.characters.length} characters total </>} </div>
                {/* options */}
                {!game.state.gameOn && 
                <div>
                    Choose an option below to play: 
                    <br />
                </div>}
                <br />
                <div>
                    {!game.state.gameOn && 
                    <div>
                        <div className = "option">
                            <button className = "hangmanbutton"onClick = {() => game.start(game.randomWord(1)[0], {isComputerWord: true})}> Guess a random word </button>
                            <select className = "hangmanselect"onChange = {event => game.handleChange({difficulty: event.target.value})}>
                                <option value = {0}> Any word </option>
                                <option value = {4}> Greater than 4 characters </option>
                                <option value = {6}> Greater than 6 characters </option>
                                <option value = {8}> Greater than 8 characters </option>
                                <option value = {10}> Greater than 10 characters </option>
                            </select>
                        </div>
                        <div className = "option">
                                <button className = "hangmanbutton" onClick = {() => {
                                    let quotesForMovie = game.movieQuotes.getQuotesByMovie(game.state.movie)
                                        game.start(quotesForMovie[
                                                Math.floor(Math.random() * quotesForMovie.length)
                                            ].quote
                                            .split("")
                                            // to filter punctuation
                                            .filter(character => /[a-z]/.test(character) || /[A-Z]/.test(character) || /[0-9]/.test(character) || character === " ")
                                            .join("")) 
                                }}> Guess a movie quote </button>
                                <select className = "hangmanselect"onChange = {event => game.handleChange({movie: event.target.value})}>
                                        {game.movies.map((movie, index) => 
                                            <option key = {index} value =  {movie.movie}> 
                                                {movie.movie} - {movie.year}
                                            </option>
                                        )}
                                </select>
                            </div>
                        <form onSubmit = {() => game.start(game.state.sentence)}> 
                            <input 
                                value = {game.state.sentence} 
                                onChange = {event => game.handleChange({sentence: event.target.value})}
                                placeholder = "Enter a custom phrase"
                                className = "game-input"
                                id = "enter"
                                
                            />
                        </form>
                    </div>} 
                </div>
                {/* input for guessing a letter */}
                {<form onSubmit = {event => {
                    event.preventDefault()
                    game.guess(game.state.letter)
                }} style = {{display: game.state.gameOn ? "block" : "none"}}>
                    <input 
                        type = "text"
                        ref = {game.inputRef}
                        value = {game.state.letter}
                        className = "game-input"
                        placeholder = "guess a letter"
                        maxLength = {1}
                        onChange = {event => {game.handleChange({letter: event.target.value.toLowerCase()})}}
                    />
                    {/*<button style = {{marginLeft: 5, width: "30%"}} className = "hangmanbutton" onClick ={() => game.guess(game.state.letter)}>
                        Submit
                        </button> */}
                </form>}
                <br />
                {game.state.triedIncorrectCharacters.length <= 1 ? null : 
                <div>
                Incorrect Guesses: 
                    <ul>
                        {game.state.triedIncorrectCharacters
                            .split("")
                            .filter(character => character !== " ")
                            .map((character, id) => 
                                <li key = {id} id = "usedCharacters"> 
                                    {character} 
                                </li>
                        )}
                    </ul>
                </div>}
                {/* Additional features */}
                {game.state.gameOn &&
                <div>
                    <button 
                        className = "hangmanbutton"
                        disabled = {game.state.hintsAvailable === 0 || game.state.lives >= 4} 
                        onClick = {game.useHint}
                        style = {{width: "30%"}}
                    > 
                        Use Hint 
                    </button>
                    <button 
                        className = "hangmanbutton"
                        style = {{marginLeft: "5%", width: "30%"}}
                        onClick = {() => {
                            game.handleChange({lives: 0})
                            game.loseGame()
                        }}
                    > 
                        Give up
                    </button>  
                    <br />
                    <span style = {{fontSize: 14, fontWeight: "lighter"}}> {game.state.hintsAvailable}/{game.initialState.hintsAvailable} hints available{game.state.lives >= 4 && "*"} </span>
                    <div style = {{fontSize: 10}}> {game.state.lives >= 4 && "* Hints are only allowed when lives are less than or equal to 3"} </div>
                </div>}
        </>
    )
}