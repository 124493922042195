import React from 'react';
import { Switch, Route } from "react-router-dom";

import Navbar from "./components/Navbar";

import Home from "./pages/Home";
import Game from "./pages/Game";

import './App.css';

export default function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path = "/">
          <Home />
        </Route>
        <Route path = "/:game">
          <Game />
        </Route>
      </Switch>
    </>
  );
}

