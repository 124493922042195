import React, { useState, useEffect, useRef } from 'react'

import "./index.css";

let randomWords = require('random-words')
const randomWord = () => randomWords(1)[0]

export default props => {
    const [ currentWord, setCurrentWord ] = useState([ ])
    const [ jumbledWord, setJumbledWord ] = useState([ ])
    const [ alert, setAlert ] = useState([ ])
    const [ rounds, setRounds ] = useState(0)
    const [ guess, setGuess ] = useState("")
    const [ correct, setCorrect ] = useState(0)
    const letterRef = useRef(null)
    useEffect(() => {
        let word = randomWord()
        setCurrentWord(word)
        setJumbledWord(jumble(word))
    }, [ rounds ])
    function jumble(word) {
        let original = word
        let jumbled = []
        for (let i in original) {
            let availableCharacters = word.split("").filter(letter => jumbled.filter(val => val === letter).length < word.split("").filter(val => val === letter).length)
            jumbled.push(availableCharacters[Math.floor(Math.random() * availableCharacters.length)])
        }
        return jumbled
    }

    function lose() {
        setAlert([<h3 style = {{background: 'red', borderRadius: 5, padding: '1em', textAlign: 'center'}}> Incorrect! </h3>, <h4 style = {{textAlign: 'center'}}> The unjumbled word was: {currentWord}</h4>])
        setTimeout(() => {
            setRounds(prev => prev + 1)
            setGuess("")
            setAlert([ ])
        }, 1500)
    }
    return (
        <>
            <br />
            <h2> Accuracy: {Math.round(100 * (correct / rounds)) ? Math.round(100 * (correct / rounds)) : 0}% </h2>
            <div style = {{display: 'grid', justifyContent: 'center'}}>
                <div>
                    {jumbledWord.map((c, i) => (
                        <div ref = {letterRef}key = {i} className = "jumbled-letter">
                            {c}
                        </div>
                    ))}
                    <form style = {{textAlign: 'center'}}onSubmit = {event => {
                        event.preventDefault()
                        if (guess.toLowerCase() === currentWord) {
                            setAlert([<h3 style = {{background: 'green', borderRadius: 5, padding: '1em', textAlign: 'center'}}> Correct! </h3>])
                            setTimeout(() => {
                                setRounds(prev => prev + 1)
                                setCorrect(prev => prev + 1)
                                setGuess("")
                                setAlert([ ])
                            }, 1000)
                        } else {
                            lose()
                        }  
                    }}>
                        <input 
                            value = {guess}
                            onChange = {event => setGuess(event.target.value)}
                            className = "game-input"
                            style = {{width: 'calc(100% - 20px)'}}
                            autoFocus = {true}
                        />
                    </form>
                </div>
                {alert}
                <div style = {{textAlign: 'center', marginTop: 50, width: '100%'}}> {alert.length === 0 && <button onClick = {lose}style = {{textAlign: 'center', width: '100%'}} className = "hangmanbutton"> Give Up</button>} </div>
            </div>
        </>
    )
}