import React from "react";

import "./LargeCard.css";

export default function LargeCard(props) {
    return (
        <>
            <div id = 'large-card'>
                {props.message && <div id = 'message'> {props.message} </div>}
                <div id = 'description'>
                    {props.children}
                </div>
            </div>
        </>
    )
}