import React from "react";
import { useParams } from "react-router-dom";

import "./Game.css";

import games from "../games";
import useToggle from "../custom hooks/useToggle";

export default function Game() {
    const params = useParams()
    const game  = games.find(game => game.name === params.game)
    const Game = game.game

    const [ fullScreen, toggleFullScreen ] = useToggle();
    const [ gameStarted, setGameStarted ] = useToggle()

    return (
        <div id = 'game-container-parent'>
            <div id = "game-container">
                <div id = "game" className = {fullScreen ? "full-screen": ""}>
                   {Game && <Game gameStarted = {gameStarted} end = {() => setGameStarted(false)} />}
                    <i className={fullScreen? "ri-fullscreen-exit-fill" : "ri-fullscreen-fill"} 
                        style = {{position: fullScreen? "fixed" : "absolute", top: 10, right: 10, cursor: "pointer"}}
                        onClick = {toggleFullScreen}></i>
                </div>
                <div id = "game-text">
                    
                    <h2> {game.name} </h2>
                    <p> {game.description} </p>
                    {!gameStarted && game.showStart && (
                        <button id = "start" onClick = {() => setGameStarted(true)}>
                            Start
                        
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
