import {useState} from "react"

export default initialState => {
    const [ state, setState ] = useState(initialState) 
    function updateState(incomingState) {
        setState(prevState => ({
            ...prevState,
            ...incomingState  
        }))
    }
    return {state, updateState, setState}
}