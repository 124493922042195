import { useState, useEffect } from "react";

import { ICard } from './card.interface'
import { shuffle } from '../../functions/shuffle'
import useTimer from "../../custom hooks/useTimer";


export default (props: any) => {
    const colors: string[] = [
        'red', 'orange', 'yellow', 'pink', 'blue', 'indigo', 'purple', 'brown'
    ]

    const turnToUrLFormat = (baseUrl: string, img: string) => `url("../${baseUrl}/${img}.jpg")`
    
    const ANIMALS: string[] = [
        'bird', 'bunny', 'cat', 'dog', 'elephant', 'fish', 'monkey', 'tiger', 'chicken'
    ].map((animal) => turnToUrLFormat('animals', animal))

    const SPORTS: string[] = [
        'baseball', 'basketball', 'biking', 'football', 'ping-pong', 'soccer','swimming', 'tennis'
    ].map(sport => turnToUrLFormat('sports', sport))

    const THEMES = [ colors, ANIMALS, SPORTS]
    const NUMBER_OF_CARDS: number = 16

    const [ useAnimation, setUseAnimation ]: [ boolean, (prevState: any) => void | boolean] = useState(true)
    const [ theme, setTheme ]: [ string[], (prevTheme: string[]) => void | string[] ] = useState(THEMES[ Math.floor(Math.random() * THEMES.length)])
   // const [ timeToMemorize, setTimeToMemorize ] = useState(5)   
    const [ cards, setCards ]: [ ICard[], (cards: any) => void | ICard[] ] = useState([ ])
    const [ cardsFlipped, setCardsFlipped ]: [ ICard[], (cards: any) => void | ICard[] ] = useState([ ])
    const [ currentCardsFlipped, setCurrentCardsFlipped ]: [ ICard[], (cards: any) => void | ICard[] ] = useState([  ])
    
    const [ alertGameOver, setAlertGameOver ]: [ boolean, (prevState: any) => void | boolean] = useState(props.gameStarted)
    const [ gameOn, setGameOn ]: [ boolean, (prevState: any) => void | boolean ] = useState(false)
    // const [ time, setTime ]: [ number, (prevTime: any) => void | number] = useState(0)
    const [ time, setTime ] = useTimer(gameOn);
    

    useEffect(() => {
        cardsFlipped.length !== 16 && setCards(Array(NUMBER_OF_CARDS).fill(null).map((val, i) => ({
            isFlipped: false,
            background: theme[ i % theme.slice(0, NUMBER_OF_CARDS / 2).length ],
        })))
         // eslint-disable-next-line
    }, [ theme ])
    // useEffect(() => {
    //     timeToMemorize > 0 ? setTimeout(() => {
    //         setTimeToMemorize(prev => prev - 1)
    //     }, 1000) : startGame()
    // }, [ timeToMemorize ])
    
    useEffect(() => {
        if (currentCardsFlipped[0] && currentCardsFlipped[1] && (currentCardsFlipped[0].background ===  currentCardsFlipped[1].background)) {
            //setTimeout(() => {
                setCards((cards: ICard[]) => cards.map((card: ICard, i: number) => card.background !== (currentCardsFlipped[0].background) ? card : ({
                    ...card,
                    isFlipped: false
                })))
                setCardsFlipped((cards: ICard[]) => [
                    ...cards,
                    ...currentCardsFlipped
                ])
                setCurrentCardsFlipped([ ])
         //  }, 1000)
        } else if (currentCardsFlipped[1]) {
            setTimeout(() => {
                setCurrentCardsFlipped([ ])
                setCards((cards: ICard[]) => cards.map((card: ICard, i: number) => 
                    !(currentCardsFlipped.some((c: ICard) => c.index === i))? card : ({
                    ...card,
                    isFlipped: true,
                    isWrong: true,
                })))
           }, useAnimation? 1500 : 500)
        }
        if (cardsFlipped.length === 16) {
            setGameOn(false)   
            props.end()
           // setTimeout(() => setAlertGameOver(true), 1500)
        }
    // eslint-disable-next-line
    }, [ currentCardsFlipped, cardsFlipped ])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setAlertGameOver(true)
    //     }, 1500)
    // }, [])
    // useEffect(() => {
    //     gameOn && setTimeout(() => {
    //         setTime((time: number) => time + 1)
    //     }, 1000) 
    // }, [ time, gameOn ])

    function startGame() {

        setAlertGameOver(false)
        setTimeout(() => {
            setCards(shuffle(Array(NUMBER_OF_CARDS).fill(null).map((val, i) => ({
                isFlipped: true,
                //theme: 
                background: theme[ i % theme.slice(0, NUMBER_OF_CARDS / 2).length ]
            }))))
        }, 0)
        setCardsFlipped([ ])
        setTime(0)
        setCurrentCardsFlipped([ ])
        setGameOn(true)
      
    }
 
    function flip(cardIndex: number): void {
        const flippedCard = cards[ cardIndex ]
        setCards((cards: ICard[]) => cards.map((card, i) => i !== cardIndex ? card : ({
            ...card,
            isFlipped: false
        })))
        setCurrentCardsFlipped((cards: ICard[]) => [
            ...cards,
            {
                ...flippedCard,
                index: cardIndex
            }
        ])

       
    }
    return {
        cards,
       // timeToMemorize,
       theme,
       colors,
       ANIMALS,
       useAnimation,
       setUseAnimation,
       SPORTS,
       setTheme,
        time,
        cardsFlipped,
        currentCardsFlipped,
        alertGameOver,
        gameOn,
        setCards,
        startGame,
        flip
    }
}