import React, {useState, useRef, useEffect }from "react"
import useStateLogic from "../../custom hooks/useStateLogic";
let randomWord = require("random-words");
let movieQuotes = require('popular-movie-quotes');
let movies = movieQuotes.getAll().reverse()
//import firebase from "../../firebase"
movies.pop()
function useGameData(props) {
    const [ sentence, setSentence ] = useState("")
    const inputRef = useRef(null)
    let initialState = {
        sentence: "",
        letter: "",
        lives: 6,
        gameOn: false,
        triedIncorrectCharacters: " ",
        triedCorrectCharacters: " ",
        difficulty: 0,
        movie: movies[0].movie,
        hintsAvailable: 1,
        points: 0,
        rounds: 0,
        score: 0 
    }
    const {state, updateState: handleChange, setState} = useStateLogic(initialState)
    const [ characters, setCharacters ] = useState([ ])
    const renderDashes = characters => 
        characters.split("").map((character, id) => 
            <div key = {id} className = {character !== " " ? "character" : "space"}/> 
        )
    useEffect(() => {
        handleChange({
            score: Math.round(state.points * 100/state.rounds)
        })
    // eslint-disable-next-line
    }, [state.rounds, state.points])

    function start(string, {isComputerWord} = {isComputerWord: false}) {
        if ((string.length <= state.difficulty && isComputerWord)) {
            while (string.length <= state.difficulty) {
              string = randomWord(1)[0]
            }
        }
        setState(initialState)
        handleChange({gameOn: [true], sentence: ""})
        setSentence(string)
        setCharacters(renderDashes(string))
        inputRef.current.focus()
    }

    function guess(letterGuessed, {givePoints} = {givePoints: true}) {
       handleChange({letter:""})
        if (sentence.toLowerCase().includes(letterGuessed) && (state.triedCorrectCharacters.includes(letterGuessed) === false)) {
            let filledCharacters = characters.map((character, i) => {
                if (sentence[i].toLowerCase() === letterGuessed && (letterGuessed !== " ")) {
                    return <div style = {{display: "inline-block"}} key = {i}> {sentence[i]}</div>
                } else {
                    return character
                }
            })
            setCharacters(filledCharacters)
            givePoints && handleChange({points: (state.points + 1)})  
            handleChange({
                triedCorrectCharacters: state.triedCorrectCharacters + letterGuessed, 
                rounds: state.rounds + 1,
            })
            if (!filledCharacters.some(character => character.props.className === "character")){
                handleChange({gameOn: false})
                setCharacters(prevCharacters => [
                    ...prevCharacters, 
                    <h4 key = "winning-text"> You won! </h4>
                ])
               
                //alert(findUser(userName))
            }
        } else if (!state.triedIncorrectCharacters.includes(letterGuessed) && !state.triedCorrectCharacters.includes(state.letter)){
            handleChange({
                rounds: state.rounds + 1,
                triedIncorrectCharacters: state.triedIncorrectCharacters + letterGuessed,
                lives: state.lives - 1
            })
            if (state.lives <= 1) {
                loseGame()
            }
        } else if (letterGuessed !== " " && letterGuessed !== "") {
            alert("You already tried that")
        }
    } 

    function useHint() {
        handleChange({hintsAvailable: state.hintsAvailable - 1})
        let availableCharacters = sentence.split("")
                    .filter(character => 
                        !state.triedCorrectCharacters.includes(character)
                    )
        let hint = availableCharacters[ Math.floor(Math.random() * availableCharacters.length) ]
        guess(hint, {givePoints: false}) // after altering Guess() to accept "guessedLetter"
    }

    function loseGame() {
        setCharacters([
            <strong key = "real-phrase"> The correct phrase was: <u>{sentence} </u></strong>,
            <h4 key = "losing-text"> You lost! </h4>
        ])
        handleChange({
            gameOn: false,
            points: 0 
        })
       
        ///getUserData().scores.hangman = savedScores
    }

    return {
        state,
        initialState,
        handleChange,  
        guess, 
        loseGame, 
        start,
        useHint, 
        characters,
        randomWord, 
        movieQuotes,
        movies,
        inputRef,
    }
}

export default useGameData


