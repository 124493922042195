import React, { useRef, useEffect, useMemo, useState } from "react";
import Vue from "vue/dist/vue.js";



Vue.config.productionTip = false

export default function Blackjack() {
    const ref = useRef(null)
    //const [reloaded, setReloaded] = useState()

    
    return (
        <div ref = {ref} id = "blackjack"></div>
    )
}