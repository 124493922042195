import Hangman from "./games/hangman/GameDisplay";
import MemoryCardGame from "./games/memory-card-game/GameDisplay";
import Blackjack from "./games/blackjack/Blackjack";
import Unjumble from "./games/unjumble-words"
export default [
    { 
        name: "blackjack", 
        description: `
            Test your skills (and luck) against the computer in this classic card game.
        `,
        img: './blackjack.jpeg',
        special: 'New!',
        game: Blackjack,
        tags: ["card"],
    },
    {
        name: "memory card game",
        description: `
            Put your memory and speed to the test while you contruct a mental map of the cards.
        `,
        img: "./memory-card-game.PNG",
        special: "New!",
        game: MemoryCardGame,
        tags: ["memory", "speed"],
        showStart: true,
    }, 
    {
        name: "unjumble words",
        description: `
            Joggle your mind as you try to unscramble these words.
        `,
        game: Unjumble,
        tags: ["word"],
        special: "New!",
        img: "./jumble.PNG"
    },
    {
        name: "hangman",
        description: `
            Play this fun word-guessing game on your own! No pencil needed!
        `,
        img: "./hangman-images/6.jpg" ,
        game: Hangman,
        tags: ["word"]
    },
    // {
    //     name: "Unjumble Words",
    //     description: `
    //         Joggle your mind and try to unscramble these words! Some of them are harder than they look!
    //     `,
    //     tags: ["word"]
    // }
]